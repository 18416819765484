<template>
  <v-container fluid>
    <v-row class="rowWidth">
      <v-col cols="5 text-left">
        <h1
          
          @click="goTo('client',$route.params.id_client)"
        >
          RETOUR A LA FICHE CLIENT
        </h1>
      </v-col>
      <v-col xl="3" lg="4"></v-col>
      <v-col cols="3" class="fullheight"></v-col>
      <v-col cols="5">
        <contact
          :client="client"
          @blackList="blackList"
          @errorFields="errorFields"
        ></contact>
      </v-col>
      <v-col cols="7">
        
<v-card id="contact">
    <v-row no-gutters>
      <v-col xl="9" lg="8" md="9" cols="9">
        <h2 :class="client.id_type_client == 1 ?'part':'bailleur'">
          LES PROCHAINES COLLECTES
        </h2>
        <div
         :class="client.id_type_client == 1 ?'part':'bailleur'"
          class="line"
        ></div>
        
        </v-col>
        </v-row>
         <v-row no-gutters  class="justify-space-around ">
         <v-col v-for="collect in client.nextCollect" :key="collect.id_collecte" xl="5" lg="5" md="6" cols="6" class="mt-5" >
        <v-card class="nextCollect">
          

              <v-row no-gutters class="collect" v-if="collect.id_collecte != undefined">
                <v-col cols="12">
 <h3>{{ $moment(collect.date_collecte).format("LL") }}</h3>
                </v-col>
            <v-col cols="12">
          <p class="horaire">
            {{
              $moment(collect.Creneau.heure_debut, "HH:mm:ss").format("HH[h]mm")
            }}
            -
            {{
              $moment(collect.Creneau.heure_fin, "HH:mm:ss").format("HH[h]mm")
            }}
          </p>
          </v-col>
           
           
        </v-row>
        <v-row id="btn" no-gutters>
          <v-col cols="12">
            <v-btn
              @click="
                toShowPopup(collect);
                mode = 'modify';
              "
              class="round xx-large white--text"
              x-large
              color="#AF2C70"
              block
              depressed
             
            >
              INFORMATIONS SUR LA COLLECTE
            </v-btn>
          </v-col>
        </v-row>
          <v-row>
      <ModifyCollectPop 
        :show="showPopup"
        @close="showPopup = false"
        :creneau="{ Creneau: currentCreneau }"
        :client="client"
        :btn="btn"
        :btn2="btn2"
        :btn3="btn3"
        :title="title"
        @deleteColl="deleteColl($event,collect.id_collecte)"
        @modifyCollecte="go('updateCollecte',collect.id_collecte)"
        @removeCollecte="removeCollecte($event,collect.id_collecte)"
        :panier="panier"
        :isCommentaireMandatory="isCommentaireMandatory"
        :mode="mode"
        :call="call"
      />
    </v-row>


        </v-card>
        </v-col>
  </v-row>
  </v-card>









      </v-col>
    </v-row>
   
  </v-container>
</template>

<script>
// Composant

import contact from "@/components/clients/contact/contact";
import ModifyCollectPop from "@/components/popups/modifyCollectePop";

//api
import api from "@/api/clients";
import apiCollecte from "@/api/collectes";


export default {
  components: {
    contact,ModifyCollectPop
  },
  data() {
    return {
      client: {},
      blackList: false,
      errorFields: [],
      showPopup:false,
      btn: { title: "Modifier cette collecte", action: "modifyCollecte" },
      btn2: "Annuler cette collecte",
      btn3: "Supprimer cette collecte",
      title: "Modification de rendez-vous",
       panier: [],
       isCommentaireMandatory: false,
      mode: "modify",
      url: process.env.VUE_APP_BASE_URL,
      currentCreneau:null,
      call:null
     
    };
  },
  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
    async reload() {
      
        api.getClientInfos(this.$route.params.id_client).then((res) => {
          this.client = res.data.client;
          console.log(this.client, "clii");
          //Store les infos clients dans vuex
          this.$store.commit("infosClient", this.client);
          this.vTotal = 0;
          this.pTotal = 0;
          this.client.historique_collectes.forEach((collect) => {
            this.vTotal += collect.volume;
            this.pTotal += collect.poids;
          });

          if(this.client.nextCollect.length > 0){
            this.nc = this.client.nextCollect[0];
          console.log(
            this.client.nextCollect[0].id_collecte,
            this.client,
            "pppppp"
          );
          }
          
          
        });
    },
        toShowPopup(collect) {
      console.log("toShowPopup",collect);
      this.panier = [];
      let collectible = [];
      this.currentCreneau = collect.Creneau
      this.call = collect.updatedAt
      apiCollecte
            .getCollecte(collect.id_collecte)
            .then((res) => {
              console.log(res,'get collecte')
              let infoCollecte = res.data.collecte[0];
              // console.log("Bruhhh ", this.infoCollecte);
              collectible = infoCollecte.Collectibles_a_Recuperers;
              let products = this.fillCart(collectible);
             
        this.showPopup = true;
         if (products.length > 0) {
        this.showPopup = true;

        products.forEach((coll) => {
          console.log("test");
          coll["url_img"] = this.url + coll.Categorie.url_logo;
          this.panier.push(coll);
          console.log("oui la 3 ", this.panier);
        });
      }
      
            });
      // let collect = this.$store.getters.getCollecteCollectibles;
      console.log(collect);
      
      // var diffHours = this.$moment(collect.date_collecte).diff(
      //   this.$moment(),
      //   "hours"
      // );

        var day_collecte = this.$moment(collect.date_collecte).set({hour:0,minute:0,second:0,millisecond:0});
        var diffDays = this.$moment(day_collecte).diff(this.$moment().set({hour:0,minute:0,second:0,millisecond:0}), 'day');
        // var diffDays = moment('2022-04-21 00:00:00').diff('2022-04-21 00:00:00', 'day');
        var today = day_collecte;
        var countDayOuvre = 0;


        if(diffDays >= 1){
          for( let i =0;i<diffDays;i++){

            today = today.subtract(1, 'days');
            let numberDay = today.isoWeekday();
            if(numberDay <=5){
              countDayOuvre ++
            }
          }
        }


      console.log('yyyyyyyyyyyyyyyyyyyyyyy', countDayOuvre);

  

      this.isCommentaireMandatory = false;
      if (countDayOuvre <= 2) {
        this.isCommentaireMandatory = true;
      }
      console.log(this.showPopup, "showPopup");
    },

    fillCart(cart) {
      console.log(cart, "cart");

      let products = cart.map((product) => {
        let p = product.Collectible;
        p.quantity = product.quantite;
        return p;
      });
       this.$store.commit("fillCart", products);
      return products
    },
    go(route,id_collecte) {
      console.log(route,id_collecte);
      this.$router.push({
        name: route,
        params: {
          id_client: this.$route.params.id_client,
          id_collecte: id_collecte,
        },
      });
    },
    deleteColl(data,id_collect) {
      console.log("delete collecte in clientvue", data,id_collect);
      apiCollecte
        .deleteCollecte(id_collect, data)
        .then((res) => {
          console.log(res.data.message);
          this.reload();
          this.showPopup = false;
        });

      this.$store.state.collecteCollectibles = [];
    },
     removeCollecte(data,id_collect) {
      console.log("remove collecte in clientvue", data,id_collect);
      apiCollecte
        .removeCollecte(id_collect)
        .then((res) => {
          console.log(res.data.message);
          this.reload();
          this.showPopup = false;
        });

        

      this.$store.state.collecteCollectibles = [];
    },
  },
  created() {
    this.reload();
  }
};
</script>

<style scoped>

h1 {
  color: #ea7e2a;
  font-size: 16px;
  font-weight: bold;
  cursor:pointer;
}
  h2{
    text-align: left;
    font-weight: bold;
    color: #93b121;
    font-size: 1.1em;
}
.bailleur {
  color : #0084c5
}


.line {
    text-align: left;
    padding: 2px 0;
    width: 1.5vw;
    background-color: #93b121;
    align-self: center;
    margin-top: 1vh;
    margin-bottom: 3vh;
  }

  .line.bailleur{
    background-color: #0084c5;
  }


  .v-card.nextCollect {
    background-color: #002B47;
    border-radius: 16px;
}



.nextCollect h2,
.nextCollect h3 {
    color: #fff;
}

.nextCollect h3 {
    color: #fff;
    font-size: 2.1rem;
    font-weight: bold;
    text-align: center;
}
.nextCollect p.horaire,.nextCollect p.details{
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
}
.nextCollect p.details{
    font-weight: 500;
}
.nextCollect p.details a{
    font-size: 0.8rem;
    text-decoration: underline;
    color: #698fa4;
}
.v-btn.xx-large {
    margin-top: 3vh;
	height: 70px!important;
	/* min-width: 92px !important; */
	padding: 0 24px!important;
}

.nextCollect p.modifyCollect {
    text-align: center;
    width: 100%;
    margin-top: 1vh;
}
.nextCollect p.modifyCollect a{
    font-size: 0.8rem;
    text-decoration: underline;
    color: #698fa4;
}

.noCollect + #btn {
    margin-top: 6.5vh;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .v-btn.xx-large {
       
    padding: 0 9px !important;
}

.nextCollect .theme--light.v-btn.v-btn--disabled.v-btn--has-bg{
    background-color: #af2c70!important;
    color: #fff!important;
    opacity: 0.5;
}


</style>
